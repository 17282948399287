import { Outlet } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { m, LazyMotion, domAnimation } from 'framer-motion'

import Navigation from '@/layouts/partials/Navigation'
import RightSidebar from '@/layouts/partials/RightSidebar'
import { useApp } from '@/lib/provider/AppProvider'
import CustomerBanner from '@/components/CustomerBanner'
import { useTenant } from '@/lib/provider/TenantProvider'
import TenantNavigation from '@/layouts/partials/TenantNavigation'
import Footer from '@/layouts/partials/Footer'

const pageVariants = {
  initial: {
    opacity: 0,
    scale: 0.99,
  },
  in: {
    opacity: 1,
    scale: 1,
  },
  out: {
    opacity: 0,
    scale: 0.9,
  },
}

const pageTransition = {
  type: 'tween',
  ease: 'anticipate',
  duration: 0.5,
}

const MemberLayout = () => {
  const [app] = useApp()
  const location = useLocation()
  const { customerId } = useTenant()

  return (
    <LazyMotion features={domAnimation} strict>
      <div
        className="flex flex-col min-h-full w-full bg-brand-1"
        id="member-layout"
        data-cy="member-layout"
      >
        {customerId ? (
          <>
            <CustomerBanner />
            <TenantNavigation />
          </>
        ) : (
          <Navigation />
        )}

        <div className="flex-1 flex bg-neutral-10 rounded-xl mx-2 mb-2 shadow-[0px_0px_20px_0px_rgba(0,0,0,0.6)]">
          <main className="min-h-full overflow-y-auto flex-1">
            <m.div
              key={location.pathname}
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
              transition={pageTransition}
            >
              <Outlet />
            </m.div>
          </main>
          {app.rightSidebar && <RightSidebar />}
        </div>

        <Footer className="px-6" />
      </div>
    </LazyMotion>
  )
}

export default MemberLayout
