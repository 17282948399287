import {
  MutableRefObject,
  ReactElement,
  ReactNode,
  useEffect,
  useMemo,
} from 'react'

import PageTitle from '@/components/PageTitle'

import {
  ContractTerm,
  WizardTabItem,
} from '@/pages/ServiceWizard/ServiceWizard.types'
import WizardPagination from '@/pages/ServiceWizard/partials/WizardPagination'
import WizardContractTerm from '@/pages/ServiceWizard/partials/WizardContractTerm'
import WizardTabs from '@/pages/ServiceWizard/partials/WizardTabs'

import { ServiceWizardContractTerm } from '@/lib/definitions/constants'
import { classNames, scrollToTop } from '@/lib/misc/Utils'
import { useWindowSize } from '@/lib/hooks/useWindowSize'

interface ServiceWizardProps {
  children: ReactNode
  pageTitle: string
  isSummaryStep: boolean
  wrapperRef: MutableRefObject<HTMLDivElement | null>
  tabs: WizardTabItem[]
  activeTabIndex: number
  contract?: number
  setContractTerm?: (term: ContractTerm) => void
  handlePreviousStep: () => void
  handleNextStep: () => void
  isPreviousStepDisabled: boolean
  isNextDisabled: boolean
  isPreviousHidden: boolean
  summarySection: ReactElement
  nextButtonLabel?: string
  backButtonLabel?: string
  hideContractTermSelector?: boolean
  isUsingExtensions?: boolean
  minimalLayout?: boolean
}

const ServiceWizard = ({
  children,
  pageTitle,
  isSummaryStep,
  contract = ServiceWizardContractTerm,
  wrapperRef,
  tabs,
  activeTabIndex,
  handlePreviousStep,
  handleNextStep,
  isPreviousStepDisabled,
  isNextDisabled,
  isPreviousHidden,
  setContractTerm,
  summarySection,
  nextButtonLabel,
  backButtonLabel,
  hideContractTermSelector,
  isUsingExtensions,
  minimalLayout,
}: ServiceWizardProps) => {
  const [innerWidth] = useWindowSize()
  const isSmallScreen = useMemo(() => innerWidth < 756, [innerWidth])

  useEffect(() => scrollToTop(wrapperRef), [wrapperRef, activeTabIndex])

  return (
    <section
      className="flex flex-col text-gray-600 h-full max-w-7xl mx-auto px-6"
      ref={wrapperRef}
    >
      <PageTitle title={pageTitle} />

      <WizardTabs tabs={tabs} activeTabIndex={activeTabIndex} />

      <div className="h-full md:grid grid-cols-12 gap-x-[1.6666%] pb-8 flex flex-col">
        {!isSummaryStep && (
          <>
            <div className="col-span-7">
              {!!tabs[activeTabIndex - 1]?.headline && (
                <h1 className="text-heading-4 mb-8 tracking-tight text-brand-4">
                  {tabs[activeTabIndex - 1].headline}
                </h1>
              )}

              {!minimalLayout &&
                !isSummaryStep &&
                !hideContractTermSelector &&
                isSmallScreen &&
                !!setContractTerm && (
                  <WizardContractTerm
                    contract={contract}
                    setContractTerm={setContractTerm}
                    minContractTerm={isUsingExtensions ? 12 : 1}
                  />
                )}

              {children}

              {!minimalLayout && (
                <div className="mt-8">
                  <WizardPagination
                    previousOnClick={handlePreviousStep}
                    nextOnClick={handleNextStep}
                    previousDisabled={isPreviousStepDisabled}
                    nextDisabled={isNextDisabled}
                    backHidden={isPreviousHidden}
                    nextButtonlabel={nextButtonLabel ?? 'Next'}
                    nextButtonCy="wizard-next-btn"
                  />
                </div>
              )}
            </div>

            {/* empty space in-between */}
            <div className="col-span-1" />
          </>
        )}
        <div
          className={classNames(
            isSummaryStep ? 'col-span-12' : 'col-span-4',
            'mt-8 md:mt-0'
          )}
        >
          {!minimalLayout &&
            !isSummaryStep &&
            !isSmallScreen &&
            !hideContractTermSelector &&
            !!setContractTerm && (
              <WizardContractTerm
                contract={contract}
                setContractTerm={setContractTerm}
                minContractTerm={isUsingExtensions ? 12 : 1}
              />
            )}
          {!minimalLayout && summarySection}
        </div>
      </div>

      {!minimalLayout && isSummaryStep && (
        <div className="mb-10">
          <WizardPagination
            previousOnClick={handlePreviousStep}
            nextOnClick={handleNextStep}
            previousDisabled={isPreviousStepDisabled}
            nextDisabled={isNextDisabled}
            backHidden={isPreviousHidden}
            nextButtonlabel="Place Order"
            backButtonlabel={backButtonLabel}
            nextButtonStyle="success"
            nextButtonCy="wizard-place-order-btn"
          />
        </div>
      )}
    </section>
  )
}

export default ServiceWizard
