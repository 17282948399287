import { ReactElement, ReactNode, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

import { useOnClickOutside } from '@/lib/hooks/useOnClickOutside'
import { classNames } from '@/lib/misc/Utils'

export interface LinkProps {
  id?: string
  label: string | ReactElement
  to?: string
  isSeparated?: boolean
  onClick?: () => void
  cy?: string
}

interface DropdownMenuProps {
  links: LinkProps[]
  children: ReactNode
  className?: string
}

const DropdownMenu = (props: DropdownMenuProps) => {
  const ref = useRef(null)
  const [toggled, setToggle] = useState(false)
  const { children, links, className = 'flex items-center' } = props

  useOnClickOutside(ref, () => setToggle(false))

  return (
    <div className={className} ref={ref}>
      <div className="relative">
        <button
          type="button"
          className="flex text-sm rounded-full"
          id="user-menu-button"
          data-cy="user-menu-button"
          aria-expanded="false"
          aria-haspopup="true"
          onClick={(event) => {
            event.preventDefault()
            event.stopPropagation()
            setToggle(!toggled)
          }}
        >
          <span className="sr-only">Open menu</span>
          {children}
        </button>

        {toggled && (
          <div
            className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-3 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-[250] text-left"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="user-menu-button"
            tabIndex={-1}
          >
            {links?.map((link, index) => {
              const itemProps = {
                className: classNames(
                  'block text-sm text-text-base',
                  link.isSeparated && 'border-t-1 mt-1 pt-1'
                ),
                role: 'menuitem',
                tabIndex: -1,
                cy: link.cy || `menu-${link.id}`,
              }

              const label = (
                <span className="block px-4 py-2 hover:bg-gray-100 transition-colors cursor-pointer">
                  {link.label}
                </span>
              )

              return link.to ? (
                <Link
                  {...itemProps}
                  key={index}
                  to={link.to}
                  onClick={() => setToggle(false)}
                  data-cy={itemProps.cy}
                >
                  {label}
                </Link>
              ) : (
                <div
                  {...itemProps}
                  key={index}
                  data-cy={itemProps.cy}
                  onClick={(event) => {
                    event.preventDefault()
                    event.stopPropagation()
                    setToggle(false)
                    link.onClick?.()
                  }}
                >
                  {label}
                </div>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

export default DropdownMenu
