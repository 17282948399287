import { FC, memo, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import {
  NavigationGroup,
  NavigationItemInterface,
} from '@/layouts/partials/NavigationGroup'
import { useWindowSize } from '@/lib/hooks/useWindowSize'

import AdjustmentsIcon from '@icons/adjustments-vertical'
import MenuIcon from '@icons/bars-3'
import HomeIcon from '@icons/home'
import CloseIcon from '@icons/x-mark'

const TenantNavigation: FC = () => {
  const [innerWidth] = useWindowSize()
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

  const mainMenuItems: NavigationItemInterface[] = useMemo(
    () => [
      {
        id: 'dashboard',
        icon: <HomeIcon />,
        to: `/`,
        title: 'Dashboard',
      },
      {
        id: 'settings',
        icon: <AdjustmentsIcon />,
        to: `/settings/users`,
        title: 'Settings',
      },
      {
        id: 'knowledge-base',
        icon: <AdjustmentsIcon />,
        to: `https://inter.link/knowledgebase`,
        title: 'Knowledge Base',
        external: true,
      },
    ],
    []
  )

  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen)

  return (
    <nav className="h-14 w-full z-20 bg-brand-1 box-content">
      <div className="h-14 w-full flex justify-between items-center relative">
        {innerWidth < 768 ? (
          <>
            {isMobileMenuOpen && (
              <div
                className="w-screen h-screen bg-text-base/70 absolute top-0 left-0"
                onClick={toggleMobileMenu}
              />
            )}
            <div className="flex flex-1 justify-center items-center bg-brand-1 h-14 absolute left-0 top-0 w-full">
              <Link to={`/`}>
                <img src="/images/logo-wide.svg" />
              </Link>
            </div>
            {isMobileMenuOpen ? (
              <CloseIcon
                className="text-white text-xl top-4 absolute left-4"
                onClick={toggleMobileMenu}
              />
            ) : (
              <MenuIcon
                className="text-white text-xl top-4 absolute left-4"
                onClick={toggleMobileMenu}
              />
            )}

            {isMobileMenuOpen && (
              <div className="flex flex-col transition ease-in-out delay-150 bg-brand-1 w-full absolute top-14 left-0 rounded-b-lg shadow-[0px_25px_50px_-5px_rgb(0,0,0,0.25)]">
                <NavigationGroup
                  onGroupItemClick={toggleMobileMenu}
                  group={{
                    className: 'flex justify-start w-full py-3',
                    items: mainMenuItems,
                  }}
                  className="flex-col w-full justify-start"
                />
              </div>
            )}
          </>
        ) : (
          <>
            <div className="flex items-center">
              <div className="hidden sm:flex px-2 py-0">
                <Link to={`/`}>
                  <img
                    src="/images/logo-wide.svg"
                    alt="Inter.link Logo"
                    className="h-10"
                  />
                </Link>
              </div>

              <NavigationGroup
                group={{
                  className: 'mr-2',
                  items: mainMenuItems,
                }}
              />
            </div>
          </>
        )}
      </div>
    </nav>
  )
}

export default memo(TenantNavigation)
