import { ReactElement, useRef, useState } from 'react'
import { classNames } from '@/lib/misc/Utils'
import { useOnClickOutside } from '@/lib/hooks/useOnClickOutside'

export const DropdownContent = ({
  children,
  content,
  className,
}: {
  children: ReactElement
  content: ReactElement
  className?: string
}) => {
  const ref = useRef(null)
  const [toggled, setToggle] = useState(false)

  useOnClickOutside(ref, () => setToggle(false))

  return (
    <div className="relative" ref={ref}>
      <span
        onClick={() => setToggle(!toggled)}
        className="relative flex justify-center h-full"
      >
        {children}
      </span>

      {toggled && (
        <div
          className={classNames('absolute z-[60] right-0 top-11', className)}
        >
          {content}
        </div>
      )}
    </div>
  )
}
