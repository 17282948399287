import { useNavigate } from 'react-router-dom'

import Button from '@/components/Button'
import ChevronRightIcon from '@icons/chevron-right'

const NoCustomerFound = () => {
  const navigate = useNavigate()

  return (
    <section className="flex flex-col text-gray-600 min-h-[300px] sm:min-h-[500px] max-w-7xl mx-auto justify-center items-center">
      <div className="text-center p-12 bg-white rounded-xl shadow-lg shadow-neutral-20">
        <h2 className="mb-2 text-heading-2 text-brand-1">Welcome!</h2>
        <p className="mb-2 max-w-sm text-lg text-zinc-500">
          We are glad, that you’re with us. Now to get started, just create a
          company first.
        </p>
        <Button
          onClick={() => {
            localStorage.setItem('redirect', location.pathname)
            navigate('/settings/create-customer')
          }}
          style={'primary'}
          className={'block mx-auto mt-4'}
          iconSuffix={<ChevronRightIcon />}
        >
          Setup Company Profile
        </Button>
      </div>
    </section>
  )
}

export default NoCustomerFound
