import { useQuery } from 'react-query'

import { GenericObject } from '@/lib/definitions/generic.types'
import { Notify } from '@/lib/misc/Notify'
import { ServiceService } from '@/lib/services/ServiceService'
import { LoaDocument } from '@/lib/definitions/api.types'
import {
  ProductComponentIdentifiers,
  ProductIdentifiers,
} from '@/lib/definitions/types'
import {
  GeoCircle,
  GeoRectangle,
} from '@/pages/ServiceWizard/ServiceWizard.types'

const serviceService = new ServiceService()

export const useLoadServices = (
  {
    serviceId,
    productId,
    serviceComponentId,
  }: {
    serviceId?: string | number
    serviceComponentId?: string | number
    productId?: ProductIdentifiers | ProductIdentifiers[]
  },
  params?: GenericObject
) =>
  useQuery(
    ['fetchServices', serviceId, productId, serviceComponentId],
    async () =>
      serviceService.getServices({ serviceId, productId, serviceComponentId }),
    {
      select: (data) => data.data,
      retry: false,
      ...params,
    }
  )

export const useLoadService = (
  serviceId: string | number,
  params?: GenericObject
) =>
  useQuery(
    ['fetchServices', serviceId],
    async () => serviceService.getService(serviceId),
    {
      onError: () => Notify.error('Could not load this service'),
      select: (data) => data.data,
      retry: false,
      ...params,
    }
  )

export const useLoadServiceComponentMetrics = (
  portId: number,
  kind: string,
  step?: number,
  start?: string,
  end?: string,
  params?: GenericObject
) =>
  useQuery(
    ['fetchServiceComponentMetrics', portId],
    async () =>
      serviceService.getServiceComponentMetrics(portId, kind, step, start, end),
    {
      select: (data) => data.data,
      ...params,
    }
  )

export const useLoadServiceComponents = (
  {
    serviceId,
    excludeDependency,
    pco,
    dependencyId,
  }: {
    serviceId?: string | number
    excludeDependency?: boolean
    pco?: ProductComponentIdentifiers | ProductComponentIdentifiers[]
    dependencyId?: string | number
  },
  params?: GenericObject
) =>
  useQuery(
    ['fetchServiceComponents', serviceId],
    async () =>
      serviceService.getServiceComponents({
        serviceId,
        excludeDependency,
        pco,
        dependencyId,
      }),
    {
      onError: () => Notify.error('Could not load this service'),
      select: (data) => data.data,
      ...params,
    }
  )

export const useLoadServiceComponent = (
  serviceComponentId: string | number,
  params?: GenericObject
) =>
  useQuery(
    ['fetchServiceComponent', serviceComponentId],
    async () => serviceService.getServiceComponent(serviceComponentId),
    {
      onError: () => Notify.error('Could not load this service component'),
      select: (data) => data.data,
      ...params,
    }
  )

export const useLoadLocations = (
  queryParams?: {
    productId?: number | string
  },
  params?: GenericObject
) =>
  useQuery(
    ['fetchLocations', queryParams?.productId],
    async () => serviceService.getLocations(queryParams),
    {
      retry: 2,
      refetchOnWindowFocus: false,
      onError: () => Notify.error('Could not load the locations'),
      select: (data) => data.data,
      ...params,
    }
  )

export const useSearchLocations = (
  queryParams?: {
    text?: string
    name?: string
    country?: string
    withinCircle?: GeoCircle
    withinRectangle?: GeoRectangle
  },
  params?: GenericObject
) =>
  useQuery(
    [
      'fetchLocations',
      queryParams?.text,
      queryParams?.name,
      queryParams?.country,
      queryParams?.withinCircle,
      queryParams?.withinRectangle,
    ],
    async () => serviceService.searchLocations(queryParams),
    {
      retry: 2,
      refetchOnWindowFocus: false,
      onError: () => Notify.error('Could not load the locations'),
      select: (data) => data.data,
      ...params,
    }
  )

export const useLoadProducts = (params?: GenericObject) =>
  useQuery(['fetchProducts'], async () => serviceService.getProducts(), {
    retry: 2,
    refetchOnWindowFocus: false,
    onError: () => Notify.error('Could not load the products'),
    select: (data) => data.data,
    ...params,
  })

export const useLoadProjects = (params?: GenericObject) =>
  useQuery(['fetchProjects'], async () => serviceService.getProjects(), {
    retry: 2,
    refetchOnWindowFocus: false,
    onError: () => Notify.error('Could not load the projects'),
    select: (data) => data.data,
    ...params,
  })

export const useLoadLoaDocuments = (
  { serviceId }: { serviceId?: string | number },
  params?: GenericObject
) =>
  useQuery(
    ['fetchLoaDocuments'],
    async () => serviceService.getLoaDocuments({ serviceId }),
    {
      onError: () => Notify.error('Could not load the LOA documents'),
      select: (data) =>
        data.data.map((document: LoaDocument) => ({
          ...document,
          url: `${
            import.meta.env.VITE_PORTAL_API_HOST || ''
          }/loa-document/download/${document.id}?token=${document.token}`,
        })),
      ...params,
    }
  )

export const useLoadServiceMetrics = (
  serviceId: number,
  kind: string,
  timeframe?: string,
  step?: number,
  end?: string,
  params?: GenericObject
) =>
  useQuery(
    ['fetchServiceMetrics', serviceId, kind, timeframe],
    async () =>
      serviceService.getServiceMetrics(serviceId, kind, timeframe, step),
    {
      select: (data) => data.data,
      ...params,
      retry: false,
    }
  )

export const useLoadServiceMetricTypes = (params: {
  product_id?: string | undefined
  service_id?: number | undefined
}) =>
  useQuery(
    ['useLoadServiceMetricTypes', params.product_id, params.service_id],
    async () =>
      serviceService.getServiceMetricTypes(
        params.product_id,
        params.service_id
      ),
    {
      select: (data) => data.data,
      retry: false,
      enabled: false,
    }
  )

export const useLoadAsSetSize = (sizes: string[]) =>
  useQuery(['getAsSetSize'], async () => serviceService.getAsSetSize(sizes), {
    retry: 2,
    refetchOnWindowFocus: false,
    select: (data) => data.data,
    enabled: false,
  })

export const useLoadAsnStatistics = (params?: GenericObject) =>
  useQuery(
    ['fetchAsnStatistics'],
    async () => {
      try {
        return await serviceService.getAsnStatistics()
      } catch (error) {
        console.error('Error fetching ASN statistics:', error)
        return { data: [] }
      }
    },
    {
      select: (data) => data?.data ?? [],
      ...params,
      retry: false,
    }
  )
