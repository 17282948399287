import { createContext, useContext, useReducer } from 'react'
import { useQuery } from 'react-query'

import FullPageSpinner from '@/components/FullPageSpinner'
import SilentFullPageErrorFallback from '@/components/SilentFullPageErrorFallback'
import { AuthService } from '@/lib/services/AuthService'

const auth = new AuthService()

export const reducer = (state: any, action: any) => {
  switch (action.type) {
    case 'setUser':
      return {
        ...state,
        user: action.payload,
        active: true,
      }

    case 'updateUser':
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      }

    default:
      return state
  }
}

export const initialState = {
  active: false,
}

export const AuthContext = createContext({
  state: initialState,
  dispatch: () => null,
})
AuthContext.displayName = 'AuthContext'

function AuthProvider(props: any) {
  const [state, dispatch] = useReducer(reducer, initialState)

  const fetchUser = async () => {
    const user = await auth.getUser()
    if (!user) return null

    dispatch({ type: 'setUser', payload: user.data })

    return user
  }

  const { isLoading, isSuccess, isError, error, status } = useQuery(
    ['getUser'],
    fetchUser,
    {
      cacheTime: 0,
      retry: 0,
      refetchOnWindowFocus: false,
      onError: (error: any) => {
        const { response } = error

        if (response?.status === 401) {
          auth.logout()
          auth.clear()
          setTimeout(() => (document.location = '/login'), 20)
        }
      },
    }
  )

  if (isLoading) {
    return <FullPageSpinner />
  }

  if (isError) {
    return <SilentFullPageErrorFallback error={error} />
  }

  if (isSuccess) {
    return <AuthContext.Provider value={[state, dispatch]} {...props} />
  }

  throw new Error(`Unhandled status: ${status}`)
}

function useAuth(): any {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }
  return context
}

export { AuthProvider, useAuth }
