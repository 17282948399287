import { memo, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import {
  NavigationGroup,
  NavigationItemInterface,
} from '@/layouts/partials/NavigationGroup'
import { useWindowSize } from '@/lib/hooks/useWindowSize'
import { useApp } from '@/lib/provider/AppProvider'
import { useAuth } from '@/lib/provider/AuthProvider'

import AdjustmentsIcon from '@icons/adjustments-vertical'
import LogoutIcon from '@icons/arrow-right-on-rectangle'
import MenuIcon from '@icons/bars-3'
import ChatIcon from '@icons/chat-bubble-left-ellipsis'
import HomeIcon from '@icons/home'
import UserIcon from '@icons/user'
import CogIcon from '@icons/cog'
import ProfileIcon from '@icons/user-circle'
import CustomersIcon from '@icons/user-group'
import CloseIcon from '@icons/x-mark'
import CubeTransparentIcon from '@icons/cube-transparent'
import LifebuoyIcon from '@icons/lifebuoy'
import { Help } from './Help'
import CommandPalette from './CommandPalette'
import { createPortal } from 'react-dom'

const Navigation = () => {
  const [appState] = useApp()
  const [auth] = useAuth()
  const [innerWidth] = useWindowSize()
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const [isCommandPaletteOpen, setIsCommandPaletteOpen] = useState(false)

  const userProfileName: string = useMemo(
    () =>
      !!auth.user.first_name && !!auth.user.last_name
        ? `${auth.user.first_name} ${auth.user.last_name}`
        : auth.user.email,
    [auth.user]
  )

  const mainMenuItems: NavigationItemInterface[] = useMemo(
    () => [
      { id: 'dashboard', icon: <HomeIcon />, to: '/', title: 'Dashboard' },
      {
        id: 'customers',
        icon: <CustomersIcon />,
        to: '/customers',
        title: 'Customers',
        isHidden:
          !appState.waffleFlags?.includes('multi_tenancy') ||
          !auth.user.company?.cid?.startsWith('R'),
      },
      {
        id: 'settings',
        icon: <AdjustmentsIcon />,
        to: '/settings/users',
        title: 'Settings',
      },
      {
        id: 'knowledge-base',
        icon: <LifebuoyIcon />,
        to: `https://inter.link/knowledgebase`,
        title: 'Knowledge Base',
        external: true,
      },
      {
        id: 'api',
        icon: <CubeTransparentIcon />,
        to: import.meta.env.VITE_API_DOCS_URL,
        title: 'API',
        external: true,
      },
      {
        id: 'tickets',
        icon: <ChatIcon />,
        to: '/tickets',
        title: 'Tickets',
        isHidden: !appState.waffleFlags?.includes('tickets'),
      },
    ],
    [appState.waffleFlags, auth.user]
  )

  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen)

  const isCommandPaletteEnabled = useMemo(() => {
    return (
      auth.user.show_company_info &&
      appState.waffleFlags?.includes('superuser_command_palette')
    )
  }, [appState, auth.user])

  useEffect(() => {
    if (!isCommandPaletteEnabled) return
    const handleKeyDown = (event: KeyboardEvent) => {
      if (
        auth.user.show_company_info &&
        (event.metaKey || event.ctrlKey) &&
        event.key === 'k'
      ) {
        event.preventDefault()
        setIsCommandPaletteOpen(!isCommandPaletteOpen)
      } else if (event.key === 'Escape' && isCommandPaletteOpen) {
        setIsCommandPaletteOpen(false)
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [
    auth.user.show_company_info,
    isCommandPaletteOpen,
    isCommandPaletteEnabled,
  ])

  return (
    <>
      <nav className="h-14 w-full z-20 box-content">
        <div className="h-14 w-full flex justify-between items-center relative">
          {innerWidth < 768 ? (
            <>
              {isMobileMenuOpen && (
                <div
                  className="w-screen h-screen bg-text-base/70 absolute top-0 left-0"
                  onClick={toggleMobileMenu}
                />
              )}
              <div className="flex flex-1 justify-center items-center bg-brand-1 h-14 absolute left-0 top-0 w-full">
                <Link to="/">
                  <img src="/images/logo-wide.svg" />
                </Link>
              </div>
              {isMobileMenuOpen ? (
                <CloseIcon
                  className="text-white text-xl top-4 absolute left-4"
                  onClick={toggleMobileMenu}
                />
              ) : (
                <MenuIcon
                  className="text-white text-xl top-4 absolute left-4"
                  onClick={toggleMobileMenu}
                />
              )}

              {isMobileMenuOpen && (
                <div className="flex flex-col transition ease-in-out delay-150 bg-brand-1 w-full absolute top-14 left-0 rounded-b-lg shadow-[0px_25px_50px_-5px_rgb(0,0,0,0.25)]">
                  <NavigationGroup
                    onGroupItemClick={toggleMobileMenu}
                    group={{
                      className: 'flex justify-start w-full py-3',
                      items: mainMenuItems,
                    }}
                    className="flex-col w-full justify-start"
                  />

                  <div className="h-4 w-full" />

                  <NavigationGroup
                    onGroupItemClick={toggleMobileMenu}
                    group={{
                      className: 'flex justify-start w-full py-3',
                      items: [
                        {
                          icon: <LifebuoyIcon />,
                          title: 'Help',
                          content: <Help />,
                        },
                        {
                          id: 'profile',
                          icon: <ProfileIcon />,
                          to: '/user-profile',
                          title: 'My Profile',
                        },

                        {
                          id: 'sign-out',
                          icon: <LogoutIcon />,
                          to: '/logout',
                          title: 'Sign Out',
                        },
                      ],
                    }}
                    className="flex-col w-full justify-start"
                  />
                </div>
              )}
            </>
          ) : (
            <>
              <div className="flex items-center">
                <div className="hidden sm:flex px-2 py-0">
                  <Link to="/">
                    <img
                      src="/images/logo-wide.svg"
                      alt="Inter.link Logo"
                      className="h-10"
                    />
                  </Link>
                </div>

                <NavigationGroup
                  group={{
                    className: 'mr-2',
                    items: mainMenuItems,
                  }}
                />
              </div>

              <div className="flex items-center">
                <NavigationGroup
                  group={{
                    className: 'opacity-50 hover:opacity-100 ml-2',
                    items: [
                      ...(isCommandPaletteEnabled
                        ? [
                            {
                              icon: <CogIcon />,
                              title: auth.user.company?.name ?? 'N/A',
                              onClick: () => {
                                setIsCommandPaletteOpen(!isCommandPaletteOpen)
                              },
                            },
                          ]
                        : []),
                      {
                        icon: <LifebuoyIcon />,
                        content: <Help />,
                      },
                      {
                        icon: <UserIcon />,
                        title: userProfileName,
                        links: [
                          {
                            id: 'profile',
                            to: '/user-profile',
                            label: 'My Profile',
                          },
                          { id: 'logout', to: '/logout', label: 'Sign out' },
                        ],
                      },
                    ],
                  }}
                />
              </div>
            </>
          )}
        </div>
      </nav>

      {isCommandPaletteEnabled &&
        isCommandPaletteOpen &&
        createPortal(
          <CommandPalette onClose={() => setIsCommandPaletteOpen(false)} />,
          document.body
        )}
    </>
  )
}

export default memo(Navigation)
