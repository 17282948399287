import { useAuth } from '@/lib/provider/AuthProvider'
import MagnifyingGlassIcon from '@icons/magnifying-glass'
import { useState, useCallback } from 'react'
import DocumentTextIcon from '@icons/document-text'
import CalendarIcon from '@icons/calendar'
import IdentificationIcon from '@icons/identification'
import Button from '@/components/Button'
import UserIcon from '@icons/user'
import {
  apiUrl,
  classNames,
  getAxiosRequestConfigWithHeaders,
} from '@/lib/misc/Utils'
import { useMutation, useQuery } from 'react-query'
import axios from 'axios'
import debounce from 'lodash/debounce'
import Tooltip from '@/components/Tooltip'
import { Notify } from '@/lib/misc/Notify'

type CommandPaletteProps = {
  onClose: () => void
}

type CustomerStatus = 'Active' | 'Inactive' | 'Prospect' | 'None'

const customerStatusColors: Record<CustomerStatus, string> = {
  Active: 'bg-status-good/80 text-status-good',
  Inactive: 'bg-status-error/80 text-status-error',
  Prospect: 'bg-status-warning/80 text-status-warning',
  None: 'bg-neutral-30 text-neutral-90',
}

type Customer = {
  id: number
  cid: string
  name: string
  vatid: string
  created_at: string
  status?: CustomerStatus
}

const REFRESH_DELAY = 1000

const CommandPalette = ({ onClose }: CommandPaletteProps) => {
  const [auth] = useAuth()
  const [search, setSearch] = useState<string>('')
  const [isImpersonating, setIsImpersonating] = useState<boolean>(false)

  // use react query to fetch companies using axios
  const { data: customers, isLoading } = useQuery({
    queryKey: ['customers', search],
    queryFn: () =>
      axios.post(
        apiUrl(`/api/v1/internal/command-palette/search`),
        {
          query: search,
          limit: 10,
        },
        getAxiosRequestConfigWithHeaders()
      ),
    select: (data) => data.data?.customers,
    retry: 0,
  })

  console.log(customers, auth.user.customer_id, auth.user)

  // Debounced search function
  // eslint-disable-next-line
  const debouncedSearch = useCallback(
    debounce((value: string) => {
      setSearch(value)
    }, 300),
    []
  )

  const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose()
    }
  }

  // useMutation to impersonate a customer
  const { mutate: impersonate } = useMutation({
    mutationFn: (customer: Customer | null) => {
      return axios.post(
        apiUrl(`/api/v1/internal/command-palette/impersonate`),
        { customer_id: customer?.id },
        getAxiosRequestConfigWithHeaders()
      )
    },
  })

  const handleImpersonate = (customer: Customer | null) => {
    impersonate(customer)

    setIsImpersonating(true)

    const seconds = REFRESH_DELAY / 1000
    Notify.success(
      customer
        ? `Impersonating customer: ${customer?.name}. Refreshing in ${seconds} seconds...`
        : `Cleared impersonation. Refreshing in ${seconds} seconds...`
    )
    setTimeout(() => {
      window.location.reload()
    }, REFRESH_DELAY)
  }

  const handleUnsetCompany = () => {
    handleImpersonate(null)
  }

  return (
    <div
      className="fixed top-0 left-0 w-full h-full bg-auxiliary-3 bg-opacity-90 z-50 backdrop-blur"
      onClick={handleOutsideClick}
    >
      <div className="max-w-2xl w-full mx-auto mt-16">
        <div className="px-4 py-2 bg-gradient-to-r from-white/30 to-white/10 text-white rounded-lg mb-4 flex justify-between">
          <p className="text-sm text-neutral-10 flex items-center gap-2">
            <span>Current Company:</span>
            <span className="font-bold text-white">
              {auth.user.company !== null ? auth.user.company?.name : 'None'}
            </span>
          </p>
          {auth.user.company !== null && (
            <Button style="flat" onClick={() => handleUnsetCompany()}>
              <span>Unset</span>
            </Button>
          )}
        </div>

        <div className="bg-white rounded-lg shadow-xl overflow-hidden">
          <div className="relative">
            <MagnifyingGlassIcon className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
            <input
              type="text"
              placeholder="Search customers..."
              className="w-full pl-12 pr-10 py-4 outline-none border-b border-neutral-20"
              autoFocus
              onChange={(e) => debouncedSearch(e.target.value)}
            />
            {isLoading && (
              <div className="absolute right-4 top-1/2 transform -translate-y-1/2">
                <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-gray-400"></div>
              </div>
            )}
          </div>
          <div className="max-h-96 overflow-y-auto py-4 min-h-48">
            {customers?.map((customer: Customer, index: number) => (
              <div
                key={index}
                className="relative flex items-center space-x-4 py-3 px-4 hover:bg-neutral-05 border-b border-neutral-10"
              >
                <div className="flex items-start">
                  <Tooltip
                    inline={true}
                    label={customer.status}
                    placement="left-arrow"
                  >
                    <span
                      className={classNames(
                        'size-4 rounded-full block',
                        customer.status
                          ? customerStatusColors[customer.status]
                          : 'bg-neutral-30 text-neutral-90'
                      )}
                    />
                  </Tooltip>
                </div>
                <div className="min-w-0 flex-1">
                  <div className="flex items-center justify-between">
                    <p className="font-medium text-gray-900 truncate">
                      {customer.name}
                    </p>
                  </div>
                  <div className="mt-2 flex items-center text-xs text-neutral-50 space-x-4">
                    {customer.created_at && (
                      <div
                        className="flex items-center"
                        title={`Created at: ${customer.created_at}`}
                      >
                        <CalendarIcon className="flex-shrink-0 mr-1.5 h-4 w-4" />
                        <span>
                          {new Date(customer.created_at).toLocaleDateString()}
                        </span>
                      </div>
                    )}
                    {customer.cid && (
                      <div
                        className="flex items-center"
                        title={`CID: ${customer.cid}`}
                      >
                        <IdentificationIcon className="flex-shrink-0 mr-1.5 h-4 w-4" />
                        <span className="truncate">{customer.cid}</span>
                      </div>
                    )}
                    {customer.vatid && (
                      <div
                        className="flex items-center"
                        title={`VAT: ${customer.vatid}`}
                      >
                        <DocumentTextIcon className="flex-shrink-0 mr-1.5 h-4 w-4" />
                        <span className="truncate">VAT: {customer.vatid}</span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex-shrink-0 self-center">
                  {customer.id !== auth.user.customer_id ? (
                    <button
                      className={classNames(
                        'inline-flex items-center px-2.5 py-1.5 border border-neutral-30 text-xs font-medium rounded text-neutral-60 bg-white hover:bg-gray-50',
                        isImpersonating ? 'opacity-50 cursor-not-allowed' : ''
                      )}
                      onClick={() => {
                        handleImpersonate(customer)
                      }}
                    >
                      <UserIcon className="size-3 mr-2" />
                      Impersonate
                    </button>
                  ) : (
                    <span className="text-neutral-50">Current</span>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CommandPalette
