import XIcon from '@icons/x-mark'
import { useState } from 'react'

import Button from '@/components/Button'
import { useApp } from '@/lib/provider/AppProvider'

const location = {
  name: 'Berlin',
}

const random = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1) + min)

function ServiceCard() {
  return (
    <div className="shadow-lg mb-4 p-2 rounded flex flex-cols">
      <div>
        <div className="bg-green-500 rounded-full h-3 w-3 mt-2" />
      </div>
      <div className="pl-2">
        <div className="text-brand-4">IP-Transit-in-Berlin</div>
        <div className="text-sm text-gray-400 flex flex-cols">
          <div className="w-20">IP-Transit</div>
          <div className="">SID981</div>
          <div className="">BER1</div>
          <div className="">100Mbps</div>
        </div>
      </div>
    </div>
  )
}

const RightSidebar = () => {
  const [, dispatch] = useApp()

  const [dummyData] = useState(() => new Array(random(3, 9)).fill('hello'))

  const close = () => dispatch({ type: 'closeRightSidebar' })

  return (
    <div className="h-full border-l border-gray-200 flex flex-col">
      <div className="relative pr-8">
        <h3 className="text-2xl font-medium m-4">{location?.name}</h3>
        <span className="absolute top-6 right-6" onClick={close}>
          <XIcon className="h-4 w-4" />
        </span>
      </div>

      <div className="overflow-y-auto flex-1 p-4">
        {dummyData.map((item, index) => (
          <ServiceCard key={index} />
        ))}

        <div>
          <Button style={'primary'}>Add new service</Button>
        </div>
      </div>
    </div>
  )
}

export default RightSidebar
