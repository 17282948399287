import { Link } from 'react-router-dom'

import { classNames, formatDate } from '@/lib/misc/Utils'
import build from '@/build-info.json'
import CodeBracketIcon from '@icons/command-line'

interface FooterPropsInterface {
  className?: string
}

const Footer = ({ className }: FooterPropsInterface) => (
  <div
    className={classNames(
      'flex flex-col space-y-4 md:space-y-0 md:flex-row items-center justify-between w-full pt-1 pb-4 text-neutral-40 sm:pb-2',
      className
    )}
  >
    <div className="text-xs bg-auxiliary-3 py-1 px-3 rounded-md text-neutral-40 flex items-center space-x-2">
      <CodeBracketIcon className="size-4" />
      <span>
        {!!build?.time ? `Build ${formatDate(build.time * 1000)}` : ''}
      </span>
    </div>

    <div className="mt-2 sm:mt-0 text-center flex flex-col space-y-4 md:flex-row md:space-y-0 md:space-x-2">
      <span className="text-neutral-50 flex flex-col space-y-2 md:flex-row md:space-y-0">
        <span>All prices without VAT</span>
        <span className="mx-2 text-tiny hidden md:inline-block">•</span>
        <span>All rights reserved</span>
      </span>

      <div className="flex items-center">
        <span className="mr-2 text-tiny hidden md:inline-block">•</span>
        <a
          target="_blank"
          href="https://inter.link/imprint/"
          className="text-link-default hover:text-text-link"
        >
          Imprint
        </a>
        <span className="mx-2 text-tiny">•</span>
        <a
          target="_blank"
          href="https://25992704.fs1.hubspotusercontent-eu1.net/hubfs/25992704/07032024%203%20Interlink%20SLA.pdf"
          className="text-link-default hover:text-text-link"
        >
          SLA
        </a>
        <span className="mx-2 text-tiny">•</span>
        <Link
          target="_blank"
          to="/legal/privacy"
          className="text-link-default hover:text-text-link"
        >
          Privacy
        </Link>
        <span className="mx-2 text-tiny">•</span>
        <Link
          target="_blank"
          to="/legal/terms"
          className="text-link-default hover:text-text-link"
        >
          Terms
        </Link>
      </div>
    </div>
  </div>
)

export default Footer
